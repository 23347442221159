<template>
  <div class="main">
    <div class="main__background" id="top">
        <img class="main__background--img" src="../../assets/textures/azul.png" alt="paint" />
    </div>
    <div class="main__header">
      <h2 class="main__header--title">CORPORATIVO</h2>
    </div>
    <div class="main__gallery">
      <div class="gallery__content">
        <img class="gallery__content--img" v-for="(image, i) in pageOfItems" :src="image" :key="i" @click="index = i">
      </div>
    </div>
    <div class="main__pagination">
      <a href="#top">
        <jw-pagination :items="images" @changePage="onChangePage"></jw-pagination>
      </a>
    </div>
    <vue-gallery-slideshow :images="pageOfItems" :index="index" @close="index = null" :labels="customLabels"></vue-gallery-slideshow>
    <div class="main__footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import VueGallerySlideshow from 'vue-gallery-slideshow';
import Footer from '../../components/Footer.vue';

/** IMAGENES */

import C1 from '../../assets/images/Fotografía/Corporativo/1.jpg';
import C2 from '../../assets/images/Fotografía/Corporativo/2.jpg';
import C3 from '../../assets/images/Fotografía/Corporativo/3.jpg';
import C4 from '../../assets/images/Fotografía/Corporativo/4.jpg';
import C5 from '../../assets/images/Fotografía/Corporativo/5.jpg';
import C6 from '../../assets/images/Fotografía/Corporativo/6.jpg';
import C7 from '../../assets/images/Fotografía/Corporativo/7.jpg';
import C8 from '../../assets/images/Fotografía/Corporativo/8.jpg';
import C9 from '../../assets/images/Fotografía/Corporativo/9.jpg';
import C10 from '../../assets/images/Fotografía/Corporativo/10.jpg';
import C11 from '../../assets/images/Fotografía/Corporativo/11.jpg';
import C12 from '../../assets/images/Fotografía/Corporativo/12.jpg';
import C13 from '../../assets/images/Fotografía/Corporativo/13.jpg';
import C14 from '../../assets/images/Fotografía/Corporativo/14.jpg';
import C15 from '../../assets/images/Fotografía/Corporativo/15.jpg';
import C16 from '../../assets/images/Fotografía/Corporativo/16.jpg';


export default {
    data () {
      return {
        images: [
          C1,C2,C3,C4,C5,C6,C7,C8,C9,C10,C11,C12,C13,C14,C15,C16
        ],
        pageOfItems: [],
        index: null
      }
    },
    methods: {
      onChangePage(pageOfItems) {
        this.pageOfItems = pageOfItems;
      }
    },
    components: {
      VueGallerySlideshow,
      Footer
    } 
}
</script>

<style scoped>
.main__background {
  position: relative;
}
.main__background--img {
  width: 100%;
  height: 570px;
  object-fit: cover;
}
.main__header {
  width: 100%;
  position: absolute;
  color: var(--txt-color);
  text-align: center;
  top: 250px;
  margin: auto;
}
.main__header--title{
  font-family: 'Yeseva One';
  font-size: 70px;
  padding: 9px 0px;
  margin: 2px;
}
.main__header--subtitle{
  font-family: var(--font);
  font-weight: 500;
  padding: 9px 0px;
  line-height: 28px;
  margin: 2px;
}
.main__pagination{
  margin-top: 50px;
  text-align: center;
}
.main__gallery{
  margin-top: -100px;
}
.gallery__content{
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.gallery__content--img{
  width: 500px;
  height: 500px;
  object-fit: cover;
  margin: 20px;
}
@media (max-width: 800px){
  .main__header--title{
    font-size: 40px;
  }
  .gallery__content--img {
    width: 200px;
    height: 200px;
  }
}
</style>